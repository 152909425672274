<template>
  <div>
    <rankingBanner></rankingBanner>
    <div class="content_area">
      <rankingNav
          :navlist='navlist'
          :navlist_active='navlist_active'
          @navEvents='navEvents'
          :loading="loading"
      ></rankingNav>
    </div>
    <div class="diaoYuTai">
      <div class="dataList content_area diaoYuTaiList" v-loading="loading">
        <div class="dataItem" v-for="(item, index) in diaoYuTaiList" :key="index">
          <div class="rankingImg">
            <img v-show="index === 0" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/one.png" alt="">
            <img v-show="index === 1" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/two.png" alt="">
            <img v-show="index === 2" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/three.png" alt="">
          </div>
          <div class="rankingNumber">
            <span>{{$t('bh_Num')}}</span>
            <span>{{ item.number}}</span>
          </div>
          <div class="point" @click="handleCompany(item)">
            <div v-if="rank_id == 1 || rank_id == 3">
              <div class="companyLogoBox">
                <el-image :src="item.logo ? item.logo : DEFAULT_LOGO" class="companyLogo" fit="scale-down"></el-image>
              </div>
              <div class="company">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
              <div class="boothBox">
                <span class="booth">{{$t('BoothNumber')}}：{{ item.booth_no }}</span>
              </div>
            </div>
            <div v-else>
              <div class="companyLogoBox">
                <el-image :src="item.avatar ? item.avatar : DEFAULT_AVATAR" class="companyLogo border50" fit="scale-down"></el-image>
              </div>
              <div class="nameBox">
                <span class="name">{{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}</span>
                <span class="job">{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}</span>
              </div>
              <div class="userCompanyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
            </div>
          </div>
          <div class="voteBox">
            <span class="vote">{{$t('voted')}}：</span>
            <span class="voteNumber">{{ item.votes }}</span>
          </div>
        </div>
        <noDataImg v-if="!loading && diaoYuTaiList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="bgF6F7F9">
      <div class="paddingT30 content_area">
        <div class="dataList content_area otherList" v-loading="loading">
          <div v-if="currentList.length > 0" class="flexBox">
            <div class="dataItem"  v-for="(item, index) in currentList" :key="index">
              <div class="rankingImg">
                <span class="rankingText">{{ item.ranking }}</span>
              </div>
              <div class="rankingNumber">
                <span>{{$t('bh_Num')}}</span>
                <span>{{ item.number}}</span>
              </div>
              <div class="point" @click="handleCompany(item)">
                <div v-if="rank_id == 1 || rank_id == 3">
                  <div class="companyLogoBox">
                    <el-image :src="item.logo ? item.logo : DEFAULT_LOGO" class="companyLogo " fit="scale-down"></el-image>
                  </div>
                  <div class="company">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
                  <div class="boothBox">
                    <span class="booth">{{$t('BoothNumber')}}：{{ item.booth_no }}</span>
                  </div>
                </div>
                <div v-else>
                  <div class="companyLogoBox">
                    <el-image :src="item.avatar ? item.avatar : DEFAULT_AVATAR" class="companyLogo" fit="scale-down"></el-image>
                  </div>
                  <div class="nameBox">
                    <span class="name">{{ item.user_name_en | priorFormat(item.user_name_zh, LOCALE) }}</span>
                    <span class="job">{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}</span>
                  </div>
                  <div class="userCompanyName">{{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}</div>
                </div>
              </div>
              <div class="voteBox">
                <span class="vote">{{$t('voted')}}：</span>
                <span class="voteNumber">{{ item.votes }}</span>
              </div>
            </div>
          </div>
          <noDataImg v-if="!loading && currentList.length === 0"></noDataImg>
        </div>
      </div>
      <el-pagination
          :hide-on-single-page="true"
          class="content_area exp_pagination" style="text-align:right; padding-bottom: 20px"
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="limit"
          :total="count"
      >
      </el-pagination>
    </div>
    <dialogCompany></dialogCompany>
  </div>
</template>

<script>
  import rankingNav from '~exp/components/rankingList/rankingNav'
  import rankingBanner from '~exp/components/rankingList/banner'
  import voteBtn from '~exp/components/rankingList/voteBtn'
  import dialogCompany from '~exp/components/rankingList/dialog_company'
  export default {
    name: "overallRankingDetail",
    components:{
      rankingNav,
      rankingBanner,
      voteBtn,
      dialogCompany
    },
    data() {
      return {

        // 参数： 加密， rank_id
        navlist_active: 3,
        navlist:[
          {
            lable: this.$t('listofWeek1'),
            id: 1,
          },
          {
            lable: this.$t('listofWeek2'),
            id: 2,
          },
          {
            lable: this.$t('listofWeek3'),
            id: 3,
          },
          {
            lable: this.$t('OverallList'),
            id: 4,
          },
        ],
        rank_id: 1,
        diaoYuTaiList: [],  //  钓鱼台数据
        currentPage: 1,
        loading: false,
        currentList: [],    //lose数据
        limit: 16,
        count: null
      }
    },
    mounted() {
      let routerParams = this._decode(this.$route.query.parameter);
      this.rank_id = routerParams.rank_id
      if (this.VTOE_TIME === 0) {
        this.navlist_active = 1
      } else {
        this.navlist_active = this.VTOE_TIME
      }
      if (routerParams.week) {
        this.navlist_active = routerParams.week
      }
      this.getData()
      this.$GLOBALEVENT.$on("DIALOFGCOMPANYCLOSE", () => {
        this.getData()
      });
    },
    methods:{
      navEvents(e){
        this.navlist_active = e.id
        this.currentPage = 1
        this.getData()
      },
      handleCompany(item) {
        let number = this.rank_id
        //打开公司详情
        let type = {
          query: {
            type:"company",
            value: 1
          }
        }

        if (number == 2 || number == 4 || number == 5) {
          type.query.type = 'people'
        }
        if (number == 1) {
          type.query.value = 2
        }
        if (number == 3) {
          type.query.value = 1
        }
        if (number == 4) {
          type.query.value = 3
        }
        if (number == 5) {
          type.query.value = 2
        }
        //打开公司详情
        this.$GLOBALEVENT.$emit("OPENCOMPANY",{show:true,params: item, type})
      },
      async getData() {
        if (this.VTOE_TIME === 0 && this.navlist_active >0 || this.VTOE_TIME ===1 && this.navlist_active >1 || this.VTOE_TIME ===2 && this.navlist_active >2 || this.VTOE_TIME ===3 && this.navlist_active >3) {
          this.diaoYuTaiList = []
          this.currentList = []
          this.count = 0
          this.loading = false
          return
        }
        try {
          this.loading = true
          let diaoYuTaiparams = {
            meeting_id: this.MEETING_ID,
            start: 0,
            week: this.navlist_active,
            type: 1
          };
          let currentparams = {
            meeting_id: this.MEETING_ID,
            start: this.currentPage - 1,
            week: this.navlist_active,
            limit: this.limit,
            type: 2
          };
          switch (Number(this.rank_id)) {
            case 1:
              diaoYuTaiparams.vote_type = 2;
              currentparams.vote_type = 2;
              break
            case 2:
              diaoYuTaiparams.vote_type = 3;
              currentparams.vote_type = 3;
              break
            case 3:
              diaoYuTaiparams.vote_type = 1;
              currentparams.vote_type = 1;
              break
            case 4:
              diaoYuTaiparams.vote_type = 5;
              currentparams.vote_type = 5;
              break
            case 5:
              diaoYuTaiparams.vote_type = 4;
              currentparams.vote_type = 4;
              break
          }
          if (this.navlist_active == 4) {
            diaoYuTaiparams.week = ''
            currentparams.week = ''
          }
          let res=await Promise.all([
            //钓鱼台
            this.$store.dispatch("rankingList/getRankingList", diaoYuTaiparams),
            //下面的
            this.$store.dispatch("rankingList/getRankingList", currentparams),
          ]).then(result=>{
            return result
          })
          this.loading = false
          //钓鱼台
          if (res[0].success){
            this.diaoYuTaiList= res[0].data
          }
          //下面的
          if (res[1].success){
            res[1].data.forEach((item, index) => {
              item.ranking = (this.currentPage - 1) * this.limit + 3 + index + 1
            })
            this.currentList= res[1].data
            this.count= res[1].count
          }
        }catch(e){

        }
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getData()
      },
    },
    destroyed(){
      this.$GLOBALEVENT.$off("DIALOFGCOMPANYCLOSE")
    },
  }
</script>

<style scoped lang="less">
.diaoYuTai {
  padding: 40px 0;
  background: linear-gradient(180deg, #FEF8DA 0%, rgba(255, 246, 233, 0.41) 100%);
}
.dataList {
  position: relative;
  .dataItem {
    width: 319px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(216, 232, 250, 0.39);
    border-radius: 10px;
    border: 1px solid #F2F2F3;
    position: relative;
    padding-top: 28px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 35px;
    .rankingImg {
      position: absolute;
      top: 0;
      left: 10px;
      width: 45px;
      height: 77px;
      background: linear-gradient(180deg, #EDEDED 0%, rgba(255, 255, 255, 0) 100%);
      text-align: center;
      padding-top: 16px;
      .rankingText {
        color: #333333;
        font-size: 24px;
        font-weight: 800;
      }
    }
    .rankingNumber {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 106px;
      padding: 4px 13px 4px 0;
      font-size: 12px;
      color: #FF9905;
      background: linear-gradient(270deg, #FFFADA 0%, rgba(255, 252, 231, 0) 100%);
      border-radius: 0px 10px 0px 0px;
      text-align: right;
    }
    .companyLogoBox {
      text-align: center;
      .companyLogo {
        width: 77px;
        height: 77px;
      }
    }
    .voteBox {
      margin-top: 30px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      .vote {
        color: #7D8695;
      }
      .voteNumber {
        color: #027FFF;
      }
    }
    .company {
      color: #333333;
      width: 224px;
      font-size: 16px;
      font-weight: 800;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      margin: 12px auto 0;
      text-align: center;
    }
    .nameBox {
      text-align: center;
      margin-top: 10px;
      .name {
        color: #333333;
        font-size: 18px;
        font-weight: 800;
        margin-right: 6px;
      }
      .job {
        font-size: 14px;
        color: #7D8695;
      }
    }
    .userCompanyName {
      color: #333333;
      width: 224px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      text-align: center;
      margin: 11px auto 0;
    }
    .boothBox {
      margin-top: 8px;
      text-align: center;
      .booth {
        color: #DD9740;
        font-size: 14px;
      }
    }
    .btnBox {
      margin-top: 25px;
      .btn {
        border: none;
        padding: 10px 18px;
        height: 30px;
        background: linear-gradient(180deg, #FDD689 0%, #FF9905 100%);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        .xinxin {
          margin-right: 10px;
        }
      }
    }
  }
}
.paddingT30 {
  padding-top: 30px;
}
.diaoYuTaiList {
  display: flex;
  justify-content: center;
  min-height: 260px;
}
.otherList {
  height: 1104px;
}
.diaoYuTaiList .dataItem{
  margin-right: 49px;
  &:nth-child(4) {
    margin-right: 0;
  }
}
.otherList .dataItem{
  width: 307px;
  max-height: 264px;
  margin-right: 24px;
  margin-bottom: 16px;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.bgF6F7F9 {
  background: #F6F7F9;
}
.flexBox {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.border50 {
  border-radius: 50%;
}
</style>









.


